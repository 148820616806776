/* fix icon colors as its horrible as it is */
:root {
    --hd-icon-button-color: var(--hd-color-neutral-icon);
    --hd-icon-button-background-color: transparent;
    --hd-icon-button-background-color-hover: var(--hd-color-neutral-surface-weak);
    --hd-icon-button-border-radius: var(--hd-border-radius-sm);
    --hd-icon-button-fill: var(--hd-color-neutral-icon);
    --hd-icon-button-fill-hover: var(--hd-color-neutral-icon-hover);
}

.hd-icon-button {
    width: var(--hd-space-4);
    aspect-ratio: 1/1;
    background-color: var(--hd-icon-button-background-color);
    color: var(--hd-icon-button-color);
    border-width: 0;
    border-radius: var(--hd-icon-button-border-radius);
    display: flex;
    justify-content: center;
    align-items: center;
    fill: var(--hd-icon-button-fill);
}

.hd-icon-button:hover {
    --hd-icon-button-background-color: var(--hd-icon-button-background-color-hover);

    cursor: pointer;
}

.hd-icon-button:focus-visible {
    outline: 0;
    box-shadow: var(--hd-focus-ring);
}
